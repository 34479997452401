.header {
    border-bottom: 0;
    padding-left: 0;
    position: fixed;
    padding-top: env(safe-area-inset-top);
}

/* Replace theme-based color values with actual colors */
.appShell {
    background-color: var(--mantine-color-white);
}

.body {
    background-color: var(--mantine-color-white);
    margin-top: 40px;
}

.tab {
    opacity: 0.5;
}

.tab[data-active] {
    text-decoration: underline;
    opacity: 1;
}

.tabPanel {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.footer {
    margin-top: 120px;
    padding-top: 0;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: var(--mantine-color-gray-0);
    border-top: 1px solid var(--mantine-color-gray-2);
}

@media (prefers-color-scheme: dark) {
    .appShell, .body {
        background-color: var(--mantine-color-dark-6);
    }

    .footer {
        background-color: var(--mantine-color-dark-6);
        border-top: 1px solid var(--mantine-color-dark-5);
    }
}

.login {
    color: var(--mantine-color-white);
    font-weight: bold;
    background-color: var(--mantine-color-green-7) !important;
}
