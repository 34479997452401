.sheetHeader {
    border-top-left-radius: var(--tscroll-sheet-radius);
    border-top-right-radius: var(--tscroll-sheet-radius);
    background-color: var(--mantine-color-gray-0);

    @media (prefers-color-scheme: dark) {
        background-color: var(--mantine-color-dark-5);
    }
}

.sheetContent {
    background-color: var(--mantine-color-gray-0);
    padding-bottom: env(safe-area-inset-bottom);
    flex: 1;

    @media (prefers-color-scheme: dark) {
        background-color: var(--mantine-color-dark-5);
    }
}
