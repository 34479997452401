.contentSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.stepSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.progress {
    font-size: smaller;
}

.fieldValue {
    font-weight: bold;
}
