.chipsDiv {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    pointer-events: initial;
}

.inputConsumer {
    pointer-events: initial;
}

.searchRow {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 0.5rem;
}

.searchIcon {
    background-color: unset;
    color: var(--mantine-primary-color-8);

    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

.cardDiv {
    border: 1px solid grey !important;
    background: var(--mantine-color-white);

    @media (prefers-color-scheme: dark) {
        background: var(--mantine-color-black);
    }
}

.cardPhotosDiv {
    height: 75px;
    min-height: 75px;
    min-width: min-content;
    display: flex;
    gap: 4px;
    align-items: flex-start;
}

.cardPhotoImg {
    height: 75px;
}

.results {
    overflow-y: auto;
}

.inline {
    display: flex;
    flex-direction: column;
    height: 100%;
}
