:root {
    --tscroll-safe-area-inset-top: env(safe-area-inset-top);
    --tscroll-safe-area-inset-bottom: env(safe-area-inset-bottom);
    --tscroll-sheet-radius: 20px;
    --keyboard-height: 0;
    --tscroll-header-height: calc(env(safe-area-inset-top) + 60px);
    --tscroll-footer-height: calc(env(safe-area-inset-bottom) + 80px);
}

body, html {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

* {
    min-height: 0;
    flex-shrink: 0;
}

.dynamic-viewport-height {
    height: 100dvh;
}

.mantine-Header-root {
    z-index: 99;
}

.mantine-Footer-root {
    z-index: 101;
}

.safeAreaPadding {
    padding-top: var(--tscroll-safe-area-inset-top);
    padding-bottom: var(--tscroll-safe-area-inset-bottom);
}

.safeAreaPaddingTop {
    padding-top: var(--tscroll-safe-area-inset-top);
}

.safeAreaPaddingBottom {
    padding-bottom: var(--tscroll-safe-area-inset-bottom);
}

.safeAreaMargins {
    margin-top: var(--tscroll-safe-area-inset-top);
    margin-bottom: var(--tscroll-safe-area-inset-bottom);
}

.safeAreaMarginTop {
    margin-top: var(--tscroll-safe-area-inset-top);
}

.safeAreaMarginBottom {
    margin-bottom: var(--tscroll-safe-area-inset-bottom);
}

.mantine-AppShell-header {
    height: var(--tscroll-header-height);
}

.mantine-AppShell-main, .mantine-AppShell-root {
    height: 100dvh;
}

.mantine-AppShell-footer {
    height: var(--tscroll-footer-height);
}

.tscroll-footer {
    padding-bottom: var(--tscroll-footer-height);
}

.tscroll-appshell-main {
    padding-top: var(--tscroll-header-height);
    padding-bottom: var(--tscroll-footer-height);
}

.mantine-Tabs-tabLabel {
    user-select: none;
}

.mantine-Header-root,
.mantine-Footer-root {
    box-shadow: 0 1px 10px 0 darkgrey;

    @media (prefers-color-scheme: dark) {
        box-shadow: 0 1px 10px 0 black;
    }
}

.mantine-Footer-root.no-shadow {
    box-shadow: 0 0 0 0;
}

.mantine-ActionIcon-root {
    background-color: unset;
    color: var(--mantine-color-text);
}

div[role=menubar] {
    padding-top: var(--tscroll-safe-area-inset-top);
}

.react-modal-sheet-container {
    border-top-left-radius: var(--tscroll-sheet-radius) !important;
    border-top-right-radius: var(--tscroll-sheet-radius) !important;
}

.react-modal-sheet-header {
    height: 20px !important;
}

.react-modal-sheet-content {
    height: 100%;
}

.keyboard-height-padded {
    padding-bottom: calc(var(--keyboard-height, 0) - var(--tscroll-safe-area-inset-bottom));
}

.no-pointer {
    user-select: none;
}

.lightbox-container div[data-testid=lightbox-image-stage] {
    flex: 1;
}

.lightbox-image-pager > div > div {
    max-width: 100%;
    max-height: 100%;
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.25s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.database-state-overlay {
    z-index: 100000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    animation-name: databaseStateOverlay;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
}

/*
   In combination with a half-second animation-duration, this gives
   us 300ms without opacity, so that the overlay is invisible unless
   it's taking a long time to re-establish connectivity.
*/
@keyframes databaseStateOverlay {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes rotate-180 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@keyframes rotate-360 {
    0% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    80% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.tscroll-floating-action-button {
    background-color: var(--mantine-primary-color-5);
    color: white;
}
