.item {
    border: 1rem solid var(--mantine-color-gray-2);
    background-color: var(--mantine-color-white);

    @media (prefers-color-scheme: dark) {
        background-color: var(--mantine-color-dark-5);
        border: 1px solid var(--mantine-color-dark-5);
    }
}

.itemDragging {
    box-shadow: var(--mantine-shadow-sm);
}
