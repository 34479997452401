.item {
    display: flex;
    margin-bottom: var(--mantine-spacing-md);
}

.itemIcon {
    margin-right: var(--mantine-spacing-md);
}

.comment {
    white-space: pre-wrap;
    overflow-x: scroll;
    flex: 1;
}
