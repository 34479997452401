.item {
    display: grid;
    align-items: center;
    border-radius: var(--mantine-radius-md);
    border: 1px solid var(--mantine-color-gray-2);
    padding: var(--mantine-spacing-sm) var(--mantine-spacing-xl);
    padding-left: var(--mantine-spacing-sm);
    padding-right: var(--mantine-spacing-sm);
    background-color: var(--mantine-color-gray-2);
    margin-bottom: var(--mantine-spacing-xs);
    margin-left: var(--mantine-spacing-xs);
    margin-right:var(--mantine-spacing-xs);

    @media (prefers-color-scheme: dark) {
        background-color: var(--mantine-color-dark-5);
        border: 1px solid var(--mantine-color-dark-5);
    }
}

.itemDragging {
    box-shadow: var(--mantine-shadow-sm);
}

.symbol {
    font-size: 30px;
    font-weight: 700;
    width: 60px;
}
